
import { defineComponent, ref, reactive, toRefs, onMounted, onActivated } from 'vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import mangerPageHeader, { BtnProps } from '@/components/btn-and-search.vue'
import createProps from '@/views/propManger/components/createProps.vue'
import { PROJECT_STATE, ROLE_STATE, MAJOR_STATE } from '@/hooks/config'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { deleteProp, getProp, getPropsTags } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import propDialog from '@/views/propManger/components/propDialog.vue'
const btnList: BtnProps[] = [
  {
    id: 1,
    btnName: '道具列表'
  },
  {
    id: 2,
    btnName: '新建道具'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: '道具编号',
    dataIndex: 'prop_code',
    key: 'prop_code'
  },
  {
    title: '道具名称',
    dataIndex: 'prop',
    key: 'prop'
  },
  {
    title: '道具标签',
    dataIndex: 'tag',
    key: 'tag'
  },
  {
    title: '备注',
    dataIndex: 'remarks',
    key: 'remarks'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
interface PaginationProps {
  current: number;
  pageSize: number;
  total: number;
}
const pagination: PaginationProps = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'PropManager',
  components: {
    mangerPageHeader,
    createProps,
    propDialog
  },
  setup () {
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      pagination: { ...pagination },
      type: null,
      search: '',
      visibleProp: false,
      propId: null,
      tagList: [],
      tag_id: null
    })
    const getPropsTagsHttp = () => {
      getPropsTags({}).then(res => {
        tableData.tagList = res.data
      })
    }
    // 获取道具
    const getPropHttp = (isDelete?: boolean) => {
      getProp({
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize,
        tag_id: tableData.tag_id
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          getPropHttp()
        }
      })
    }
    const changeSelect = (value: any) => {
      tableData.pagination.current = 1
      tableData.tag_id = value
      getPropHttp()
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(getPropHttp)
    // 翻页
    const changePage = (pagination: PaginationProps) => {
      tableData.pagination.current = pagination.current
      getPropHttp()
    }
    // 跳转编辑页面
    const goEidt = (item: any) => {
      const id = item.id
      router.push({ name: 'createProps', params: { id } })
      sessionStorage.setItem('propObj', JSON.stringify(item))
    }
    // 跳转详情页面
    const goDetail = (item: any) => {
      // router.push({ name: 'projectDetail', params: { id } })
    }
    // 搜索
    const fetchSearch = () => {
      tableData.pagination.current = 1
      getPropHttp()
    }
    // 删除
    const deletePropHttp = (id: number) => {
      showConfirm({
        content: '此操作不可逆，确定删除该道具吗?',
        callBack: () => {
          deleteProp({
            id
          }).then(() => {
            message.success('删除成功')
            getPropHttp()
          })
        }
      })
    }
    // 打开操作功能弹框
    const openControl = (item: any) => {
      const id = item.id
      router.push({ name: 'createControl', params: { id } })
      // tableData.visibleProp = true
      // tableData.propId = item.id
    }
    onMounted(() => {
      getPropsTagsHttp()
    })
    onActivated(() => {
      getPropHttp()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      ...toRefs(tableData),
      PROJECT_STATE,
      goEidt,
      goDetail,
      changePage,
      fetchSearch,
      deletePropHttp,
      openControl,
      changeSelect
    }
  }
})
