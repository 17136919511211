<template>
  <a-modal
    width="60%"
    :visible="visible"
    title="添加操作功能"
    okText="确定"
    cancelText="取消"
    @cancel="handleCancel"
    @ok="handelOK">
    <a-form
    :label-col="{ span: 2 }"
    :wrapper-col="{ span: 21 }"
    :model="formState"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item label="操作功能名：" name="control">
      <a-input
        v-model:value="formState.control"
        placeholder="请输入操作功能名称"
        :maxlength="100"
        autocomplete="off"
      ></a-input>
    </a-form-item>
    <a-form-item label="操作功能id：" name="control_code">
      <a-input
        v-model:value="formState.control_code"
        placeholder="请输入操作功能id"
        :maxlength="100"
        autocomplete="off"
        type="number"
      ></a-input>
    </a-form-item>
    <a-form-item label="备注：">
      <a-input
        v-model:value="formState.remarks"
        placeholder="请输入备注"
        :maxlength="100"
        autocomplete="off"
      ></a-input>
    </a-form-item>
  </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import {
  addControl
} from '@/api/index'
export default defineComponent({
  props: {
    visible: {},
    propId: {}
  },
  setup (props, ctx) {
    const formRef = ref()
    const formState = reactive({
      control: '',
      control_code: null,
      remarks: ''
    })
    const rules = {
      control: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入操作功能名称',
          whitespace: true
        }
      ],
      control_code: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入操作功能id',
          whitespace: true
        }
      ]
    }
    const handleCancel = () => {
      ctx.emit('update:visible', false)
    }
    const addControlHttp = () => {
      addControl(Object.assign(formState, {
        prop_id: parseInt(props.propId),
        control_code: parseInt(formState.control_code)
      })).then(res => {
        message.success('添加操作功能成功')
        handleCancel()
      })
    }
    const handelOK = () => {
      formRef.value
        .validate()
        .then(() => {
          addControlHttp()
          // callback()
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
    return {
      formRef,
      formState,
      rules,
      handleCancel,
      handelOK
    }
  }
})
</script>
